import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (

  <Layout>
    <SEO title="Home" />
      <div className="">
        <div className="blog-post">
          <div className="text-xl">{frontmatter.title}</div>
          <div className="text-sm">{frontmatter.date}</div>
          <div
            className="pt-2"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <Link to="/blog">Back to overview</Link>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $path } }
      ) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`